import { createTheme } from "@material-ui/core";

export const LightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      paper: "#fffffe",
      default: "#94a1b2",
    },
    primary: {
      main: "#16161a",
    },
    secondary: {
      main: "#7f5af0",
    },
    error: {
      main: "#fa5246",
    },
    stroke: {
      main: "#001e1d",
    },
  },
  typography: {
    fontFamily: "Lexend",
  },
  shape: {
    borderRadius: 15,
  },
});
export const DarkTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      paper: "#94a1b2",
      default: "#16161a",
    },
    primary: {
      main: "#16161a",
    },
    secondary: {
      main: "#7f5af0",
    },
    error: {
      main: "#fa5246",
    },
    success: {
      main: "#2cb67d",
    },
    stroke: {
      main: "#fffffe",
    },
  },
  typography: {
    fontFamily: "Lexend",
  },
  shape: {
    borderRadius: 15,
  },
});
export const BumbleBee = createTheme({
  palette: {
    background: {
      paper: "#e8eddf",
      default: "#cfdbd5",
    },
    primary: {
      main: "#242423",
    },
    secondary: {
      main: "#f5cb5c",
    },
    error: {
      main: "#fa5246",
    },
    success: {
      main: "#2cb67d",
    },
    stroke: {
      main: "#333533",
    },
  },
  typography: {
    fontFamily: "Lexend",
  },
  shape: {
    borderRadius: 15,
  },
});
export const Ocean = createTheme({
  palette: {
    background: {
      paper: "rgb(255, 255, 255)",
      default: "rgb(217, 217, 217)",
    },
    primary: {
      main: "rgb(40, 75, 99)",
    },
    secondary: {
      main: "rgb(60, 110, 113,0.85)",
    },
    error: {
      main: "#fa5246",
    },
    success: {
      main: "#2cb67d",
    },
    stroke: {
      main: "rgb(53, 53, 53)",
    },
  },

  typography: {
    fontFamily: "Lexend",
  },
  shape: {
    borderRadius: 10,
  },
});

export const Popular = createTheme({
  palette: {
    background: {
      paper: "rgb(255, 255, 255)",
      default: "rgb(238, 238, 238)",
    },
    primary: {
      main: "rgb(34, 40, 49)",
    },
    secondary: {
      main: "rgb(0, 173, 181)",
    },
    error: {
      main: "#fa5246",
    },
    success: {
      main: "#2cb67d",
    },
    stroke: {
      main: "rgb(57, 62, 70)",
    },
  },

  typography: {
    fontFamily: "Lexend",
  },
  shape: {
    borderRadius: 10,
  },
});
