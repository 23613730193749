import React, { useState, useEffect } from "react";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Login/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Insights from "./Screens/Insights/Insights";
import EditGarments from "./Screens/Edits/EditGarment";
import CreateBrandFocus from "./Screens/CreateContent/CreateBrandFocus";
import CreateSpotlight from "./Screens/CreateContent/CreateSpotlight";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { Ocean, Popular } from "./styling/Themes";
import BulkUpload from "./Screens/BulkUpload/BulkUpload";
import Social from "./Screens/Socials/Social";
import CreateUser from "./Screens/Create User/CreateUser";
import EditGarment from "./Screens/Edits/EditGarment";

function App() {
  let theme = Popular;
  const [authenticated, setAuthenticated] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [idToken, setIdToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [admin, setAdmin] = useState(false);
  const [profile, setProfile] = useState(false);

  useEffect(() => {
    return () => {
      setAuthenticated(false);
      setAccessToken("");
      setIdToken("");
      setRefreshToken("");
      setAdmin(false);
    };
  }, []);

  function LogOut() {
    setAuthenticated(false);
    setAccessToken("");
    setIdToken("");
    setRefreshToken("");
    setAdmin(false);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {authenticated ? (
        <Router>
          <Navbar LogOut={LogOut} admin={admin} />
          <Routes>
            <Route
              exact
              path="/"
              element={<Insights accessToken={accessToken} admin={admin} />}
            />
            <Route
              exact
              path="/approvals"
              element={<Home accessToken={accessToken} admin={admin} />}
            />
            <Route
              exact
              path="/editgarments"
              element={<EditGarments accessToken={accessToken} admin={admin} />}
            />
            <Route
              exact
              path="/createspotlight"
              element={
                <CreateSpotlight accessToken={accessToken} admin={admin} />
              }
            />
            <Route
              exact
              path="/createbrandfocus"
              element={
                <CreateBrandFocus accessToken={accessToken} admin={admin} />
              }
            />
            <Route
              exact
              path="/bulkupload"
              element={
                <BulkUpload
                  accessToken={accessToken}
                  admin={admin}
                  profile={profile}
                />
              }
            />
            <Route
              exact
              path="/socials"
              element={<Social accessToken={accessToken} admin={admin} />}
            />
            <Route
              exact
              path="/createuser"
              element={<CreateUser accessToken={accessToken} admin={admin} />}
            />
            <Route
              exact
              path="/editgarment"
              element={<EditGarment accessToken={accessToken} admin={admin} />}
            />
          </Routes>
        </Router>
      ) : (
        <Login
          setAuthenticated={setAuthenticated}
          setAccessToken={setAccessToken}
          setIdToken={setIdToken}
          setRefreshToken={setRefreshToken}
          setAdmin={setAdmin}
          setProfile={setProfile}
        />
      )}
    </ThemeProvider>
  );
}

export default App;
