import React, { useEffect, useState, useCallback } from "react";
import { FormControl, Typography, Select, MenuItem } from "@material-ui/core";

import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Doughnut } from "react-chartjs-2";
import baseURL from "../../../assets/config/baseUrl";

const filters = ["Type", "Brand", "Decade", "Season"];

export default function PieChart({ accessToken }) {
  const [pieState, setPieState] = useState();
  const [filter, setFilter] = useState("Brand");

  const getCount = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}insights/countby/${filter}`, config)
      .then((res) => {
        let data = [];
        let labels = [];
        let backgroundColors = [];

        res.data.data.map((record) => {
          var blue = Math.floor(Math.random() * 256);
          var red = Math.floor(Math.random() * 256);
          var green = Math.floor(Math.random() * 256);

          //get only one colour
          red = 0;
          var randomColor =
            "rgb(" + red + ", " + green + ", " + blue + ", 0.65)";
          labels.push(record.name);
          data.push(record.count);
          backgroundColors.push(randomColor);
          return true;
        });

        let state = {
          labels: labels,
          datasets: [
            {
              label: "Rainfall",
              backgroundColor: backgroundColors,

              data: data,
            },
          ],
        };
        setPieState(state);
      })
      .catch((err) => console.log(err));
  }, [accessToken, filter]);

  useEffect(() => {
    getCount();
  }, [filter, getCount]);

  return (
    <div>
      <div style={{ padding: 10 }}>
        <FormControl style={{ width: 200 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="outlined"
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            style={{ height: 50, width: "100%" }}
          >
            {filters.map((f) => {
              return (
                <MenuItem key={f} value={f}>
                  {f}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Typography
          style={{ fontStyle: "italic", marginTop: 10, color: "#8d8f91" }}
        >
          (now limited to top 25)
        </Typography>
      </div>
      {pieState && (
        <div style={{ padding: 20 }}>
          <Doughnut
            data={pieState}
            options={{
              title: {
                display: true,
                text: "Average Rainfall per month",
                fontSize: 20,
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              borderRadius: 10,
            }}
          />
        </div>
      )}
    </div>
  );
}
