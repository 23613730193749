import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

import axios from "axios";
import baseURL from "../assets/config/baseUrl";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CustomAutocomplete from "./CustomAutocomplete";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formContainer: {
    textAlign: "center",
  },

  buttonContainer: {
    width: "70%",
    height: 100,
  },
  formControl: {
    width: "20%",
    marginRight: 20,
  },
  select: {
    width: "100%",
  },
  formItem: {
    fontFamily: "Lexend",
    fontSize: 15,
    color: "1F1f1f",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    color: "white",
  },
  paper: {
    textAlign: "left",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    boxShadow: theme.shadows[10],
  },
  text: {
    margin: 15,
    fontFamily: "Lexend",
    fontSize: 20,
    color: "#f4f5f5",
  },
  success: {
    borderRadius: 10,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.background.default,
    },
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderRadius: 10,
    borderWidth: 2,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.background.default,
      borderColor: theme.palette.error.main,
    },
  },
}));

export default function FilterBar({ setFilters, accessToken }) {
  const classes = useStyles();

  // State Items
  const [brand, setBrand] = useState();
  const [type, setType] = useState();
  const [user, setUser] = useState();

  //List Items
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [users, setUsers] = useState([]);

  // Reset Variable
  const [reset, setReset] = useState(false);
  // Functions as Callbacks
  const getUsers = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}general/getbytype/User`, config)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);
  const getTypes = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}general/getbytype/Type`, config)
      .then((res) => {
        setTypes(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);
  const getBrands = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}general/getbytype/Brand`, config)
      .then((res) => {
        setBrands(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);

  // useEffect Hooks
  useEffect(() => {
    getTypes();
    getUsers();
    getBrands();
  }, [getTypes, getUsers, getBrands]);

  useEffect(() => {
    setFilters({ brand: brand, type: type, user: user });
  }, [brand, type, user, setFilters]);

  return (
    <Paper className={classes.paper}>
      <FormControl className={classes.formControl}>
        <CustomAutocomplete
          options={brands}
          label={"select a brand"}
          onChange={(value) => {
            setBrand(value);
          }}
          reset={reset}
          optionValue={"name"}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <CustomAutocomplete
          options={users}
          label={"select a user"}
          onChange={(value) => {
            setUser(value);
          }}
          reset={reset}
          optionValue={"username"}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <CustomAutocomplete
          options={types}
          label={"select a type"}
          onChange={(value) => {
            setType(value);
          }}
          reset={reset}
          optionValue={"name"}
        />
      </FormControl>
      <Button
        variant={"outlined"}
        onClick={() => {
          setBrand();
          setType();
          setUser();
          setFilters();
          setReset(!reset);
        }}
        className={classes.error}
      >
        CLEAR
      </Button>
    </Paper>
  );
}
