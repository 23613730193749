import React from "react";
import { Grid } from "@material-ui/core";

import RowItem from "./RowItem";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function ItemContainer({
  items,
  setSavedItems,
  savedItems,
  brands,
  types,
  decades,
  seasons,
}) {
  function addToSave(item) {
    setSavedItems([...savedItems, item]);
  }
  return (
    <div
      container
      spacing={2}
      scrollable="true"
      style={{
        overflowX: "auto",
        height: "100%",
        width: "100%",
        display: "flex",

        listStyle: "none",
        "&::WebkitScrollbar": {
          width: "0.4em",
        },
        "&::WebkitScrollbarTrack": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::WebkitScrollbarThumb": {
          backgroundColor: "rgba(0,0,0,.1)",
          outline: "1px solid slategrey",
        },
      }}
    >
      {Object.entries(items).map((item) => {
        return (
          <RowItem
            item={item}
            key={item[0]}
            brands={brands}
            types={types}
            decades={decades}
            seasons={seasons}
            addToSave={addToSave}
          />
        );
      })}
    </div>
  );
}

export default ItemContainer;
