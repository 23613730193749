import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@material-ui/core";

import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ProductCard from "../../Components/ProductCard";
import ProductRow from "../../Components/ProductRow";
import InstructionCard from "./InstructionCard";
import baseURL from "../../assets/config/baseUrl";
import CustomAutocomplete from "../../Components/CustomAutocomplete";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formContainer: {
    textAlign: "center",
  },

  buttonContainer: {
    width: "70%",
    height: 100,
  },

  select: {
    width: "100%",
  },
  formItem: {
    fontFamily: "Lexend",
    fontSize: 15,
    color: "1F1f1f",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    color: "white",
  },
  text: {
    margin: 15,
    fontFamily: "Lexend",
    fontSize: 20,
    color: theme.palette.primary.main.contrastText,
  },
  paper: {
    textAlign: "left",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    boxShadow: theme.shadows[10],
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[10],
  },
  formControl: {
    width: "100%",
  },

  buttonGroup: {
    border: `3px solid ${theme.palette.stroke.main}`,
    borderRadius: 100,
    overflow: "hidden",
    marginTop: theme.spacing(4),
  },
  heading: {
    margin: 15,
    fontSize: 30,
    color: theme.palette.stroke.main,
  },
  success: {
    borderRadius: 10,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.background.default,
    },
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderRadius: 10,
    borderWidth: 2,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.background.default,
      borderColor: theme.palette.error.main,
    },
  },
  addButton: {
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.background.default,
    borderRadius: 200,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.background.default,
      borderColor: theme.palette.primary.main,
    },
  },
}));
export default function CreateBrandFocus({ accessToken, admin }) {
  const classes = useStyles();
  let navigate = useNavigate();
  useEffect(() => {
    if (!admin) {
      navigate("/");
    }
  }, [admin]);
  //POST values
  const [selectedFile, setSelectedFile] = useState();
  const [description, setDescription] = useState();
  const [selectedBrand, setSelectedBrand] = useState();
  const [iconicItems, setIconicItems] = useState([]);
  const [identifyInstructions, setIdentifyInstructions] = useState([]);
  const [selectedPrizes, setSelectedPrizes] = useState([]);

  //List Values
  const [brands, setBrands] = useState([]);
  const [garments, setGarments] = useState([]);
  const [canUpload, setCanUpload] = useState(false);
  const [selectedGarments, setSelectedGarments] = useState([]);

  // Functions as Constants
  const handleFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleBrandSelect = (event) => {
    setSelectedBrand(event.target.value);
  };

  // Functions as Callbacks
  const getBrands = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}general/getbytype/Brand`, config)
      .then((res) => {
        setBrands(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);

  const getGarments = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}general/filtergarments/Brand/${selectedBrand}`, config)
      .then((res) => {
        setGarments(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken, selectedBrand]);

  // Functions
  async function Upload() {
    setCanUpload(false);
    const config = {
      headers: { accessToken: accessToken },
    };

    const data = new FormData();
    data.append("brand", selectedBrand);
    data.append("description", description);
    //remove unselected items
    const newList = iconicItems.filter((t) => {
      return selectedGarments.includes(t.id);
    });
    data.append("iconicItems", JSON.stringify(newList));
    data.append("prizes", JSON.stringify(selectedPrizes));

    let files = [selectedFile];
    let instructions = [];

    identifyInstructions.forEach((i, index) => {
      files.push(i.file);
      instructions.push({
        title: i.title,
        description: i.description,
        index: index + 1,
      });
    });
    files.forEach((image, i) => {
      data.append("images", image);
    });
    data.append("instructions", JSON.stringify(instructions));
    await axios
      .post(`${baseURL}brandfocus`, data, config)
      .then((res) => {
        resetData();
      })
      .catch((err) => console.log(err));
  }
  function resetData() {
    setSelectedFile();
    setDescription();
    setSelectedBrand();
    setIconicItems([]);
    setIdentifyInstructions([]);
    setSelectedPrizes([]);
    setCanUpload(false);
    setSelectedGarments([]);
  }

  // useEffects
  useEffect(() => {
    getBrands();
  }, [getBrands]);

  useEffect(() => {
    if (selectedBrand) {
      getGarments();
    }
  }, [selectedBrand, getGarments]);

  useEffect(() => {
    if (
      iconicItems &&
      identifyInstructions &&
      description &&
      selectedBrand &&
      selectedFile
    ) {
      var isNull = false;
      for (var i in identifyInstructions) {
        if (
          identifyInstructions[i].title == null ||
          identifyInstructions[i].description == null ||
          identifyInstructions[i].file == null
        ) {
          isNull = true;
        }
      }
      for (var x in iconicItems) {
        if (
          iconicItems[x].title == null ||
          iconicItems[x].description == null
        ) {
          isNull = true;
        }
      }

      if (!isNull) {
        setCanUpload(true);
      } else {
        setCanUpload(false);
      }
    } else {
      setCanUpload(false);
    }
  }, [
    identifyInstructions,
    description,
    selectedBrand,
    selectedFile,
    iconicItems,
  ]);

  return (
    <Grid container>
      <Grid
        item
        xs={4}
        style={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Paper className={classes.colouredPaper}>
          <Typography>Brand Focus Details:</Typography>
        </Paper>
        <Paper className={classes.paper}>
          <div style={{ margin: 10 }}>
            <FormControl style={{ width: "100%" }}>
              <CustomAutocomplete
                options={brands}
                label={"select a brand"}
                onChange={(value) => {
                  setSelectedBrand(value);
                }}
                reset={true}
                optionValue={"name"}
              />
            </FormControl>
          </div>
          <div style={{ margin: 10 }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Description"
              variant="outlined"
              multiline
              maxRows={10}
              value={description}
              onChange={handleDescription}
              style={{ width: "100%" }}
            />
          </div>
        </Paper>
        <Paper className={classes.colouredPaper}>
          <Typography>Upload Cover Image:</Typography>
        </Paper>
        <div>
          {!selectedFile ? (
            <Button
              variant="contained"
              component="label"
              className={classes.success}
            >
              Upload File
              <input type="file" name="file" hidden onChange={handleFile} />
            </Button>
          ) : (
            <img
              src={URL.createObjectURL(selectedFile)}
              alt="Brand Focus"
              style={{ width: "100%" }}
            />
          )}
        </div>
      </Grid>
      <Grid item xs={8}>
        <Paper className={classes.colouredPaper}>
          <Typography>Select Garments In Focus:</Typography>
        </Paper>
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={2}
            scrollable="true"
            style={{
              overflowY: "auto",
              height: 500,
              width: "100%",
              listStyle: "none",
              "&::WebkitScrollbar": {
                width: "0.4em",
              },
              "&::WebkitScrollbarTrack": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::WebkitScrollbarThumb": {
                backgroundColor: "rgba(0,0,0,.1)",
                outline: "1px solid slategrey",
              },
            }}
          >
            {garments.map((garment) => {
              return (
                <ProductCard
                  garment={garment}
                  key={garment.id}
                  selectedGarments={selectedGarments}
                  setSelectedGarments={setSelectedGarments}
                />
              );
            })}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.colouredPaper}>
          <Typography>Select Iconic Items:</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={2}
            scrollable="true"
            style={{
              overflowY: "auto",
              height: 500,
              width: "100%",
              listStyle: "none",
              "&::WebkitScrollbar": {
                width: "0.4em",
              },
              "&::WebkitScrollbarTrack": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::WebkitScrollbarThumb": {
                backgroundColor: "rgba(0,0,0,.1)",
                outline: "1px solid slategrey",
              },
            }}
          >
            {garments
              .filter((garm) => selectedGarments.includes(garm.id))
              .map((garment) => {
                return (
                  <ProductRow
                    key={garment.id}
                    garment={garment}
                    setIconicItems={setIconicItems}
                    iconicItems={iconicItems}
                  />
                );
              })}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.colouredPaper}>
          <Typography>Add Instructions for Identifying Brand: </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={2}
            scrollable="true"
            style={{
              overflowY: "auto",
              height: 500,
              width: "100%",
              listStyle: "none",
              "&::WebkitScrollbar": {
                width: "0.4em",
              },
              "&::WebkitScrollbarTrack": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::WebkitScrollbarThumb": {
                backgroundColor: "rgba(0,0,0,.1)",
                outline: "1px solid slategrey",
              },
            }}
          >
            <Button
              variant="outlined"
              component="label"
              onClick={() => {
                setIdentifyInstructions([
                  ...identifyInstructions,
                  { title: "", description: "", file: null },
                ]);
              }}
              className={classes.addButton}
            >
              ADD
            </Button>
            {identifyInstructions.map((instruction, index) => {
              return (
                <InstructionCard
                  key={index}
                  identifyInstructions={identifyInstructions}
                  setIdentifyInstructions={setIdentifyInstructions}
                  index={index}
                />
              );
            })}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.colouredPaper}>
          <Typography>Select Garments for Prize Giveaway:</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={2}
            scrollable="true"
            style={{
              overflowY: "auto",
              height: 500,
              width: "100%",
              listStyle: "none",
              "&::WebkitScrollbar": {
                width: "0.4em",
              },
              "&::WebkitScrollbarTrack": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::WebkitScrollbarThumb": {
                backgroundColor: "rgba(0,0,0,.1)",
                outline: "1px solid slategrey",
              },
            }}
          >
            {garments.map((garment) => {
              return (
                <ProductCard
                  garment={garment}
                  key={garment.id}
                  selectedGarments={selectedPrizes}
                  setSelectedGarments={setSelectedPrizes}
                />
              );
            })}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.colouredPaper} style={{ textAlign: "right" }}>
          <Button
            variant={"contained"}
            disabled={!canUpload}
            onClick={() => {
              Upload();
            }}
            className={classes.success}
          >
            SUBMIT
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
