import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@material-ui/core";

import axios from "axios";
import baseURL from "../../assets/config/baseUrl";
import { useNavigate } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ProductCard from "../../Components/ProductCard";
import FilterBar from "../../Components/FilterBar";
import CustomAutocomplete from "../../Components/CustomAutocomplete";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formContainer: {
    textAlign: "center",
  },
  select: {
    width: "100%",
  },
  formItem: {
    fontFamily: "Lexend",
    fontSize: 15,
    color: "1F1f1f",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    color: "white",
  },
  text: {
    margin: 15,
    fontFamily: "Lexend",
    fontSize: 20,
    color: "#f4f5f5",
  },
  paper: {
    textAlign: "left",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    boxShadow: theme.shadows[10],
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[10],
  },
  formControl: {
    width: "100%",
  },

  success: {
    borderRadius: 10,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.background.default,
    },
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderRadius: 10,
    borderWidth: 2,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.background.default,
      borderColor: theme.palette.error.main,
    },
  },
  buttonGroup: {
    border: `3px solid ${theme.palette.stroke.main}`,
    borderRadius: 100,
    overflow: "hidden",
    marginTop: theme.spacing(4),
  },
}));
export default function CreateSpotlight({ accessToken, admin }) {
  // Only allow Admins
  let navigate = useNavigate();
  useEffect(() => {
    if (!admin) {
      navigate("/");
    }
  }, [admin]);
  const classes = useStyles();

  //POST values
  const [selectedFile, setSelectedFile] = useState();
  const [description, setDescription] = useState();
  const [name, setName] = useState();
  const [selectedGarments, setSelectedGarments] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  //List Values
  const [users, setUsers] = useState([]);
  const [garments, setGarments] = useState([]);
  const [canUpload, setCanUpload] = useState(false);
  const [filters, setFilters] = useState([]);

  // Functions as Constants
  const handleFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleUserSelect = (event) => {
    setSelectedUser(event.target.value);
  };

  // Functions as Callbacks
  const getUsers = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}general/getbytype/User`, config)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);

  const getGarments = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .post(`${baseURL}garments/filter`, filters, config)
      .then((res) => {
        setGarments(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken, filters]);

  // Functions

  async function Upload() {
    setCanUpload(false);
    const config = {
      headers: { accessToken: accessToken },
    };

    const data = new FormData();
    data.append("name", name);
    data.append("description", description);
    data.append("featured", selectedGarments);
    data.append("userID", selectedUser);
    data.append("images", selectedFile);

    await axios
      .post(`${baseURL}spotlight`, data, config)
      .then((res) => {
        Reset();
      })
      .catch((err) => console.log(err));
  }
  function Reset() {
    setCanUpload(false);
    setDescription();
    setName();
    setGarments([]);
    setSelectedFile();
    setSelectedGarments([]);
    setFilters([]);
  }
  // UseEffect Functions
  useEffect(() => {
    getUsers();
    getGarments();
  }, [getGarments, getUsers]);

  useEffect(() => {
    if (
      selectedGarments &&
      name &&
      description &&
      selectedUser &&
      selectedFile
    ) {
      setCanUpload(true);
    } else {
      setCanUpload(false);
    }
  }, [selectedGarments, name, description, selectedUser, selectedFile]);

  return (
    <Grid container>
      <Grid item xs={4} style={{ textAlign: "center" }}>
        <Paper className={classes.colouredPaper}>
          <Typography>Spotlight Details:</Typography>
        </Paper>

        <Paper className={classes.paper}>
          <div>
            <TextField
              id="outlined-flexible"
              label="Name"
              maxRows={10}
              value={name}
              onChange={handleName}
              variant="outlined"
              style={{ width: "100%", marginTop: 10 }}
            />
          </div>
          <div>
            <TextField
              id="outlined-multiline-flexible"
              label="Description"
              variant="outlined"
              multiline
              maxRows={10}
              value={description}
              onChange={handleDescription}
              style={{ width: "100%", marginTop: 10 }}
            />
          </div>
          <FormControl fullWidth style={{ marginTop: 10 }}>
            <CustomAutocomplete
              options={users}
              label={"select a user"}
              onChange={(value) => {
                setSelectedUser(value);
              }}
              reset={true}
              optionValue={"username"}
            />
          </FormControl>
        </Paper>
        <Paper className={classes.colouredPaper}>
          <Typography>Upload Cover Image:</Typography>
        </Paper>
        {!selectedFile ? (
          <Button
            variant="contained"
            component="label"
            className={classes.success}
          >
            Upload File
            <input type="file" name="file" hidden onChange={handleFile} />
          </Button>
        ) : (
          <Paper className={classes.colouredPaper}>
            <img
              alt="Spotlight Cover"
              src={URL.createObjectURL(selectedFile)}
              style={{ width: "100%" }}
            />
          </Paper>
        )}
      </Grid>
      <Grid item xs={8}>
        <Paper className={classes.colouredPaper}>
          <Typography>Select Garments:</Typography>
        </Paper>
        <FilterBar
          accessToken={accessToken}
          filters={filters}
          setFilters={setFilters}
        />
        <Paper className={classes.paper}>
          <Grid
            container
            scrollable={"true"}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: 530,
              listStyle: "none",
              "&::WebkitScrollbar": {
                width: "0.4em",
              },
              "&::WebkitScrollbarTrack": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::WebkitScrollbarThumb": {
                backgroundColor: "rgba(0,0,0,.1)",
                outline: "1px solid slategrey",
              },
            }}
          >
            {garments.map((garment) => {
              return (
                <ProductCard
                  key={garment.id}
                  garment={garment}
                  selectedGarments={selectedGarments}
                  setSelectedGarments={setSelectedGarments}
                />
              );
            })}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.colouredPaper} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            component="label"
            disabled={!canUpload}
            className={classes.success}
            onClick={() => Upload()}
          >
            UPLOAD
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
