import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid } from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[2],
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[2],
  },
}));
export default function ProductCard({
  selectedGarments,
  setSelectedGarments,
  garment,
}) {
  const [selected, setSelected] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (selectedGarments.includes(garment.id)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedGarments, garment.id]);
  return (
    <Grid item key={garment.id}>
      <Paper
        className={selected ? classes.colouredPaper : classes.paper}
        style={{ width: 150, height: 150, margin: 7.5 }}
        onClick={() => {
          if (selected) {
            let filteredArray = selectedGarments.filter(
              (item) => item !== garment.id
            );
            setSelectedGarments(filteredArray);
          } else {
            setSelectedGarments([...selectedGarments, garment.id]);
          }
        }}
      >
        <img
          src={`https://low-res-images-processed.s3.eu-west-2.amazonaws.com/${garment.images[0]}`}
          style={{ width: "100%", height: "100%" }}
          alt={garment.name}
        />
      </Paper>
    </Grid>
  );
}
