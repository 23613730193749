import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  Divider,
} from "@material-ui/core";
import { styled, useTheme } from "@mui/material/styles";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";
const DrawerHeader = styled("div")(({}) => ({
  display: "flex",
  alignItems: "center",
  padding: 15,
  // necessary for content to be below app bar
  justifyContent: "flex-end",
}));
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  navItem: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.light,
    textDecoration: "none",
    fontSize: 16,
    width: "100%",
    padding: theme.spacing(2),
    borderBottomWidth: 2,

    borderBottomColor: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: "#ebf0fa",
      cursor: "pointer",
    },
  },
  title: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  environmentTag: {
    color: theme.palette.secondary.light,
    marginLeft: 20,
    textAlign: "center",
  },
  logOutButton: {
    color: theme.palette.primary.light,
    textDecoration: "none",
    fontSize: 16,
    height: "100%",
    padding: theme.spacing(2),
    borderBottomWidth: 2,
    justifySelf: "flex-end",
    alignItems: "center",
    display: "flex",
    borderRadius: 10,
    borderBottomColor: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.dark,
      cursor: "pointer",
    },
  },
  helperText: {
    color: theme.palette.secondary.dark,
    textDecoration: "none",
    fontSize: 16,
    padding: theme.spacing(1),
    justifySelf: "flex-end",
    alignItems: "center",
    display: "flex",
  },
  drawerHeader: {
    height: headerHeight,
  },
}));
const headerHeight = 75;

function Navbar({ LogOut, admin }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="static"
        open={open}
        style={{
          height: headerHeight,
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.title}>
            <Typography variant="h3">STRUT</Typography>
            <div className={classes.environmentTag}>
              <Typography variant="h5">
                [{process.env.REACT_APP_STAGE}]
              </Typography>
            </div>
          </div>
          <div className={classes.logOutButton} onClick={() => LogOut()}>
            <div> LOGOUT</div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer sx={{}} variant="persistent" anchor="left" open={open}>
        <DrawerHeader className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Typography className={classes.helperText}>Admin</Typography>
        <Link to="/" className={classes.navItem} onClick={handleDrawerClose}>
          Dashboard
        </Link>
        <Typography className={classes.helperText}>Create Content</Typography>
        {admin && (
          <Link
            to="/approvals"
            className={classes.navItem}
            onClick={handleDrawerClose}
          >
            Approve Items
          </Link>
        )}
        {admin && (
          <Link
            to="/editgarment"
            className={classes.navItem}
            onClick={handleDrawerClose}
          >
            Edit Garment
          </Link>
        )}
        {admin && (
          <Link
            to="/createspotlight"
            className={classes.navItem}
            onClick={handleDrawerClose}
          >
            Spotlight
          </Link>
        )}
        {admin && (
          <Link
            to="/createbrandfocus"
            className={classes.navItem}
            onClick={handleDrawerClose}
          >
            Brand Focus
          </Link>
        )}

        <Typography className={classes.helperText}>Users</Typography>
        <Link
          to="/bulkupload"
          className={classes.navItem}
          onClick={handleDrawerClose}
        >
          Upload Collection
        </Link>
        {admin && (
          <Link
            to="/createuser"
            className={classes.navItem}
            onClick={handleDrawerClose}
          >
            Create User
          </Link>
        )}
        <Typography className={classes.helperText}>Marketing</Typography>
        <Link
          to="/socials"
          className={classes.navItem}
          onClick={handleDrawerClose}
        >
          Captioned Photos
        </Link>
      </Drawer>
    </Box>
  );
}
export default Navbar;
