import React, { useEffect, useState, useCallback } from "react";
import { FormControl, Typography, Select, MenuItem } from "@material-ui/core";

import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Bar } from "react-chartjs-2";
import baseURL from "../../../assets/config/baseUrl";

const filters = ["Type", "Brand", "Decade", "Season"];

export default function BarChart({ accessToken }) {
  const [barState, setBarState] = useState();
  const [filter, setFilter] = useState("Brand");
  const getCount = useCallback(async () => {
    const config = {
      headers: {
        accessToken: accessToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    await axios
      .get(`${baseURL}insights/countby/${filter}`, config)
      .then((res) => {
        let data = [];
        let labels = [];
        //Background Colour
        var blue = Math.floor(Math.random() * 256);
        var red = Math.floor(Math.random() * 256);
        var green = Math.floor(Math.random() * 256);
        //get only one colour
        red = 0;
        var randomColor = "rgb(" + red + ", " + green + ", " + blue + ", 0.65)";

        res.data.data.forEach((record) => {
          labels.push(record.name);
          data.push(record.count);
        });
        let state = {
          labels: labels,
          datasets: [
            {
              label: "No. Items",
              backgroundColor: randomColor,
              borderColor: "rgba(0,0,0,1)",
              data: data,
            },
          ],
        };
        setBarState(state);
      })
      .catch((err) => console.log(err));
  }, [accessToken, filter]);

  useEffect(() => {
    getCount();
  }, [filter, getCount]);

  return (
    <div>
      <div style={{ padding: 10 }}>
        <FormControl style={{ width: 300 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filter}
            variant="outlined"
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            style={{ height: 50, width: "100%" }}
          >
            {filters.map((f) => {
              return (
                <MenuItem key={f} value={f}>
                  {f}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Typography
          style={{ fontStyle: "italic", marginTop: 10, color: "#8d8f91" }}
        >
          (now limited to top 25)
        </Typography>
      </div>
      {barState && (
        <Bar
          data={barState}
          options={{
            borderRadius: 3,
          }}
        />
      )}
    </div>
  );
}
