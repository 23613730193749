import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@material-ui/core";
import Alert from "@mui/material/Alert";
import axios from "axios";
import baseURL from "../../assets/config/baseUrl";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Popup from "../../Components/Popup";
import dropDownData from "./DeclineOptions";
import CustomAutocomplete from "../../Components/CustomAutocomplete";
import { useThemeWithoutDefault } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "pink",
  },
  formContainer: {
    textAlign: "center",
  },
  paper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[10],
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[10],
  },
  formControl: {
    width: "100%",
    marginTop: 10,
  },
  formItem: {
    fontFamily: "Lexend",
    fontSize: 15,
  },
  success: {
    borderRadius: 10,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.background.default,
    },
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderRadius: 10,
    borderWidth: 2,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.background.default,
      borderColor: theme.palette.error.main,
    },
  },
}));

export default function Home({ accessToken, admin }) {
  //Screen Config
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successfulUpload, setSuccessfulUpload] = useState(false);
  //General
  const [garmentID, setGarmentID] = useState("");
  const [garments, setGarments] = useState([]);
  const [garmentData, setGarmentData] = useState();
  const [userData, setUserData] = useState();
  //dropdown values
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [decades, setDecades] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [message, setMessage] = useState([]);

  //new values
  const [brand, setBrand] = useState();
  const [collabBrand, setCollabBrand] = useState();
  const [type, setType] = useState();
  const [decade, setDecade] = useState();
  const [year, setYear] = useState();
  const [season, setSeason] = useState();
  const [name, setName] = useState();
  const [sampleURL, setSampleURL] = useState("");

  // Functions as constants
  const handleChange = (event) => {
    setGarmentID(event.target.value);
  };
  const handleMessage = (event) => {
    setMessage(event.target.value);
  };

  // Functions as callbacks
  const getUserData = useCallback(
    async (userID) => {
      const config = {
        headers: { accessToken: accessToken },
      };
      await axios
        .get(`${baseURL}insights/userscore/${userID}`, config)
        .then((res) => {
          setUserData(res.data.data);
        })
        .catch((err) => console.log(err));
    },
    [accessToken]
  );
  const getData = useCallback(async () => {
    const config = {
      headers: { accessToken: `${accessToken}` },
    };
    await axios
      .get(`${baseURL}garments/${garmentID}/1587`, config)
      .then((res) => {
        getSampleURL(res.data.data);
        setGarmentData(res.data.data);
        getUserData(res.data.data.creator.id);
        setName(res.data.data.name);
        setType(res.data.data.Type.id);
        setBrand(res.data.data.Brand.id);
        setDecade(res.data.data.Decade.id);
        setSeason(res.data.data.Season.id);
        setYear(res.data.data.Year);
        setCollabBrand(res.data.data.collabBrand.id);
      })
      .catch((err) => console.log(err));
  }, [accessToken, garmentID, getUserData]);

  const getPending = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}garments/getpending`, config)
      .then((res) => {
        setGarments(res.data.data);
      })
      .catch((err) => console.log(err));

    await axios
      .get(`${baseURL}general/getbytype/Brand`, config)
      .then((res) => {
        setBrands(res.data.data);
      })
      .catch((err) => console.log(err));

    await axios
      .get(`${baseURL}general/getbytype/Type`, config)
      .then((res) => {
        setTypes(res.data.data);
      })
      .catch((err) => console.log(err));
    await axios
      .get(`${baseURL}general/getbytype/Season`, config)
      .then((res) => {
        setSeasons(res.data.data);
      })
      .catch((err) => console.log(err));
    await axios
      .get(`${baseURL}general/getbytype/Decade`, config)
      .then((res) => {
        setDecades(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);

  // Functions
  function getSampleURL(data) {
    let url = `https://www.google.com/search?q=${data.name.replace(" ", "+")}+${
      data.Brand ? data.Brand.name.replace(" ", "+") : ""
    }+${data.Type ? data.Type.name.replace(" ", "+") : ""}+${
      data.Decade ? data.Decade.name : ""
    }`;
    setSampleURL(url);
  }

  async function approved() {
    const config = {
      headers: { accessToken: accessToken },
    };
    let data = {
      brand: brand,
      type: type,
      decade: decade,
      season: season,
      garmentID: garmentID,
      name: name,
      collabBrand: collabBrand,
      year: year,
    };
    if (collabBrand != -1) {
      data["collabBrand"] = collabBrand;
    }
    await axios
      .post(`${baseURL}garments/update`, data, config)
      .then((res) => {
        getPending();
        setGarmentID("");
        setSampleURL("");
        setGarmentData();
        getUserData();
        setName();
        setType();
        setBrand();
        setDecade();
        setSeason();
        setYear();
        setCollabBrand();
        setSuccessfulUpload(true);
        setShowAlert(true);
      })
      .catch((err) => {
        setSuccessfulUpload(false);
        setShowAlert(true);
      });
  }
  async function declined() {
    const config = {
      headers: { accessToken: accessToken },
    };
    let data = { id: garmentID, message: message };
    await axios
      .post(`${baseURL}garments/decline`, data, config)
      .then((res) => {
        getPending();
        alert("declined");
        setGarmentID("");
        setGarmentData();
      })
      .catch((err) => console.log(err));
  }
  const handleYear = (event) => {
    if (event.target.value == "") {
      setYear(-1);
    } else if (event.target.value >= -1 && event.target.value <= 9) {
      setYear(event.target.value);
    }
  };

  // UseEffects
  useEffect(() => {
    if (garmentID) {
      getData();
    }
  }, [garmentID, getData]);

  useEffect(() => {
    getPending();
    return () => {
      setGarmentData();
      setGarmentID("");
      setGarments([]);
      setUserData({ approved: 10, declined: 3 });
    };
  }, [getPending]);

  return (
    <>
      {showAlert &&
        (successfulUpload ? (
          <Alert
            severity="success"
            onClose={() => {
              setShowAlert(false);
            }}
          >
            <Typography>Upload Successful</Typography>
          </Alert>
        ) : (
          <Alert
            severity="error"
            onClose={() => {
              setShowAlert(false);
            }}
          >
            <Typography>Upload Failed</Typography>
          </Alert>
        ))}
      <div style={{ margin: 20 }}>
        <Popup
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          disabled={false}
          title={"Select a Reason"}
        >
          <FormControl className={classes.formControl}>
            <InputLabel
              id="demo-simple-select-label"
              className={classes.formItem}
            >
              select a message
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={message}
              rounded={"true"}
              onChange={handleMessage}
            >
              {dropDownData.map((message, index) => {
                return (
                  <MenuItem key={index} value={message}>
                    {message}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: 150 }}
            disabled={false}
            onClick={(e) => {
              setModalOpen(false);
              declined();
            }}
          >
            decline
          </Button>
        </Popup>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Paper className={classes.colouredPaper}>
              <Typography>Garment Images</Typography>
            </Paper>
            <Paper
              sx={{
                minHeight: 400,
              }}
              elevation={3}
              className={classes.paper}
            >
              <Carousel showThumbs={false}>
                {garmentData &&
                  garmentData.images.map((image, index) => {
                    return (
                      <div>
                        <img
                          key={index}
                          src={`https://high-res-images-processed.s3.eu-west-2.amazonaws.com/${image}`}
                          alt={`productImage-${index}`}
                          style={{ width: "100%" }}
                        />
                      </div>
                    );
                  })}
              </Carousel>
            </Paper>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <Paper className={classes.colouredPaper}>
              <Typography>Garment Selection and Information</Typography>
            </Paper>
            <Paper
              sx={{
                minHeight: 400,
              }}
              elevation={3}
              rounded={"true"}
              className={classes.paper}
            >
              <FormControl fullWidth>
                <CustomAutocomplete
                  options={garments}
                  label={"select a garment"}
                  onChange={(value) => {
                    setGarmentID(value);
                  }}
                  reset={reset}
                  optionValue={"id"}
                />
              </FormControl>
            </Paper>
            <Paper className={classes.colouredPaper}>
              <Typography>Garment Data</Typography>
            </Paper>
            <Paper className={classes.paper}>
              <Typography>
                Sample Link:
                <a href={sampleURL} target="_blank" rel="noreferrer">
                  {` ${sampleURL}`}
                </a>
              </Typography>
            </Paper>
            <Paper
              sx={{
                minHeight: 400,
              }}
              elevation={3}
              rounded={"true"}
              className={classes.paper}
            >
              {garmentData && (
                <>
                  <Typography>Name: {garmentData.name}</Typography>
                  <Typography>Brand: {garmentData.Brand.name}</Typography>
                  {garmentData.collabBrand && (
                    <Typography>
                      Collab: {garmentData.collabBrand.name}
                    </Typography>
                  )}
                  <Typography>Type: {garmentData.Type.name}</Typography>
                  <Typography>Decade: {garmentData.Decade.name}</Typography>
                  <Typography>Season: {garmentData.Season.name}</Typography>
                  <Typography>Year: {garmentData.Year}</Typography>
                </>
              )}
            </Paper>
            <Paper className={classes.colouredPaper}>
              <Typography>User Data</Typography>
            </Paper>
            <Paper
              sx={{
                minHeight: 400,
              }}
              elevation={3}
              rounded={"true"}
              className={classes.paper}
            >
              {userData && (
                <>
                  <Typography>approved {userData.approved}</Typography>
                  <Typography>declined {userData.declined}</Typography>
                  <Typography>
                    K/D{" "}
                    {userData.declined === 0
                      ? userData.approved / (userData.declined + 1)
                      : userData.approved / (userData.declined + 1)}
                  </Typography>
                </>
              )}
            </Paper>
          </Grid>

          <Grid item xs={4}>
            <Paper className={classes.colouredPaper}>
              <Typography>Reassign Values</Typography>
            </Paper>
            <Paper
              sx={{
                minHeight: 400,
              }}
              elevation={3}
              rounded={"true"}
              className={classes.paper}
            >
              {garmentData && (
                <>
                  <TextField
                    id="outlined-flexible"
                    label="Name"
                    maxRows={10}
                    value={name ? name : ""}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    style={{ width: "95%" }}
                  />
                  <FormControl className={classes.formControl}>
                    <CustomAutocomplete
                      options={brands}
                      label={"select a brand"}
                      onChange={(value) => {
                        setBrand(value);
                      }}
                      reset={reset}
                      optionValue={"name"}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <CustomAutocomplete
                      options={[{ name: "No Collab", id: -1 }, ...brands]}
                      label={"select a collab"}
                      onChange={(value) => {
                        setCollabBrand(value);
                      }}
                      reset={reset}
                      optionValue={"name"}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <CustomAutocomplete
                      options={types}
                      label={"select a type"}
                      onChange={(value) => {
                        setType(value);
                      }}
                      reset={reset}
                      optionValue={"name"}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <CustomAutocomplete
                      options={decades}
                      label={"select a decade"}
                      onChange={(value) => {
                        setDecade(value);
                      }}
                      reset={reset}
                      optionValue={"name"}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <CustomAutocomplete
                      options={seasons}
                      label={"select a season"}
                      onChange={(value) => {
                        setSeason(value);
                      }}
                      reset={reset}
                      optionValue={"name"}
                    />
                  </FormControl>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="year"
                    variant="outlined"
                    value={year}
                    onChange={handleYear}
                    className={classes.formControl}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </>
              )}
            </Paper>
            <Paper
              sx={{
                minHeight: 400,
              }}
              elevation={3}
              rounded={"true"}
              className={classes.paper}
              style={{ textAlign: "center" }}
            >
              <Button
                variant="contained"
                className={classes.success}
                disabled={!garmentID}
                onClick={() => {
                  approved();
                }}
              >
                APPROVE
              </Button>
              <Button
                variant="outlined"
                className={classes.error}
                disabled={!garmentID}
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                DECLINE
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
