import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import JSZip from "jszip";
import axios from "axios";
import baseURL from "../../assets/config/baseUrl";
import saveAs from "file-saver";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ProductCard from "../../Components/ProductCard";
import FilterBar from "../../Components/FilterBar";
import Popup from "../../Components/Popup";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formContainer: {
    textAlign: "center",
  },
  select: {
    width: "100%",
  },
  formItem: {
    fontFamily: "Lexend",
    fontSize: 15,
    color: "1F1f1f",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    color: "white",
  },
  text: {
    margin: 15,
    fontFamily: "Lexend",
    fontSize: 20,
    color: "#f4f5f5",
  },
  paper: {
    textAlign: "left",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    boxShadow: theme.shadows[10],
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[10],
  },
  formControl: {
    width: "100%",
  },

  success: {
    borderRadius: 10,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.background.default,
    },
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderRadius: 10,
    borderWidth: 2,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.background.default,
      borderColor: theme.palette.error.main,
    },
  },
  buttonGroup: {
    border: `3px solid ${theme.palette.stroke.main}`,
    borderRadius: 100,
    overflow: "hidden",
    marginTop: theme.spacing(4),
  },
}));

export default function Social({ accessToken, admin }) {
  const classes = useStyles();
  let navigate = useNavigate();

  //POST values
  const [selectedGarments, setSelectedGarments] = useState([]);
  //List Values
  const [users, setUsers] = useState([]);
  const [garments, setGarments] = useState([]);
  const [canUpload, setCanUpload] = useState(false);
  const [filters, setFilters] = useState([]);

  //Options
  const [isRectangle, setIsRectangle] = useState(true);

  //Config
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [downloaded, setDownloaded] = useState(false);

  // Functions as Constants
  const handleCheckbox = (e) => {
    setIsRectangle(!isRectangle);
  };
  // Functions as Callbacks
  const getUsers = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}general/getbytype/User`, config)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);

  const getGarments = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .post(`${baseURL}garments/filter`, filters, config)
      .then((res) => {
        setGarments(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken, filters]);

  //POST
  function postToLambda(data) {
    return axios
      .post(
        `https://9wzafil0e9.execute-api.eu-west-2.amazonaws.com/Testing/ImageOverlaySTRUT`,
        data
      )
      .then((response) => {
        return response.data.message;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }
  async function getImagesWithOverlay() {
    setButtonDisabled(true);
    setModalOpen(true);
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .post(
        `${baseURL}garments/productcarddata`,
        { garmentIDs: selectedGarments },
        config
      )
      .then((res) => {
        let promises = res.data.data.map((data) => {
          return postToLambda(data);
        });
        Promise.all(promises)
          .then((results) => {
            var zip = new JSZip();
            zip.file("README.txt", "lol u actually read me\n");
            var folder = zip.folder("images");
            results.map((data, index) => {
              const imSource = data;
              const fileName = `${index}.png`;
              folder.file(fileName, imSource, { base64: true });
            });
            setDownloaded(true);
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, "files");
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  }

  function reset() {
    setButtonDisabled(false);
    setSelectedGarments([]);
    setCanUpload(false);
    setDownloaded(false);
    setFilters([]);
  }

  // UseEffect Functions
  useEffect(() => {
    getUsers();
    getGarments();
  }, [getGarments, getUsers]);

  useEffect(() => {
    if (selectedGarments.length !== 0) {
      setCanUpload(true);
    } else {
      setCanUpload(false);
    }
  }, [selectedGarments]);

  return (
    <>
      <Popup
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        disabled={false}
        title={downloaded ? "Downloaded" : "Downloading..."}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!downloaded ? (
            <CircularProgress style={{ margin: 20 }} />
          ) : (
            <Typography style={{ margin: 15 }}>
              Images rendered! The zip file should start downloading in the next
              few seconds.{" "}
            </Typography>
          )}
          <Button
            variant="contained"
            color="secondary"
            disabled={!downloaded}
            onClick={(e) => {
              setModalOpen(false);
              reset();
            }}
          >
            CLOSE
          </Button>
        </div>
      </Popup>
      <Grid container>
        <Grid item xs={4} style={{ textAlign: "center" }}>
          <Paper className={classes.colouredPaper}>
            <Typography>Options:</Typography>
          </Paper>

          <Paper className={classes.paper}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRectangle}
                  onChange={handleCheckbox}
                  disabled={true}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Rectangle"
            />
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper className={classes.colouredPaper}>
            <Typography>Select Garments:</Typography>
          </Paper>
          <FilterBar
            accessToken={accessToken}
            filters={filters}
            setFilters={setFilters}
          />
          <Paper className={classes.paper}>
            <Grid
              container
              spacing={2}
              scrollable={"true"}
              style={{
                overflowY: "auto",
                height: 530,
                listStyle: "none",
                "&::WebkitScrollbar": {
                  width: "0.4em",
                },
                "&::WebkitScrollbarTrack": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::WebkitScrollbarThumb": {
                  backgroundColor: "rgba(0,0,0,.1)",
                  outline: "1px solid slategrey",
                },
              }}
            >
              {garments.map((garment) => {
                return (
                  <ProductCard
                    key={garment.id}
                    garment={garment}
                    selectedGarments={selectedGarments}
                    setSelectedGarments={setSelectedGarments}
                  />
                );
              })}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            className={classes.colouredPaper}
            style={{ textAlign: "right" }}
          >
            <Button
              variant="contained"
              component="label"
              disabled={!canUpload && !buttonDisabled}
              className={classes.success}
              onClick={() => getImagesWithOverlay()}
            >
              DOWNLOAD
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
