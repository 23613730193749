import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@material-ui/core";
import Alert from "@mui/material/Alert";
import ProductCard from "../../Components/ProductCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "pink",
  },
  formContainer: {
    textAlign: "center",
  },
  paper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[10],
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[10],
  },
  formControl: {
    width: "100%",
    marginTop: 10,
  },
  formItem: {
    fontFamily: "Lexend",
    fontSize: 15,
  },
  success: {
    borderRadius: 10,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.background.default,
    },
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderRadius: 10,
    borderWidth: 2,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.background.default,
      borderColor: theme.palette.error.main,
    },
  },
}));

function GarmentPanel({ garment, selectedGarment, setSelectedGarment }) {
  const classes = useStyles();
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    if (selectedGarment == garment.id) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedGarment]);
  return (
    <Paper
      className={selected ? classes.colouredPaper : classes.paper}
      style={{ width: 150, height: 150, margin: 7.5 }}
      onClick={() => {
        setSelectedGarment(garment.id);
      }}
    >
      <img
        src={`https://low-res-images-processed.s3.eu-west-2.amazonaws.com/${garment.images[0]}`}
        style={{ width: "100%", height: "100%" }}
        alt="garment"
      />
    </Paper>
  );
}

export default GarmentPanel;
