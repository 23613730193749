import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, TextField } from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    boxShadow: theme.shadows[2],
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[2],
  },
}));
export default function ProductRow({ iconicItems, setIconicItems, garment }) {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const classes = useStyles();

  useEffect(() => {
    const newList = iconicItems.filter((t) => t.id !== garment.id);
    newList.push({ id: garment.id, title: title, description: description });
    setIconicItems(newList);
  }, [title, description]);
  return (
    <Grid item xs={3}>
      <Paper className={classes.colouredPaper}>
        <div style={{ margin: 10 }}>
          <img
            src={`https://high-res-images-processed.s3.eu-west-2.amazonaws.com/${garment.images[0]}`}
            style={{ width: 100, height: 100, margin: 10 }}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="title"
            multiline
            variant="outlined"
            maxRows={10}
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            style={{ width: "100%", marginTop: 10 }}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Description"
            multiline
            variant="outlined"
            maxRows={10}
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            style={{ width: "100%", marginTop: 10 }}
          />
        </div>
      </Paper>
    </Grid>
  );
}
