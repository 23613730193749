import React, { useState } from "react";
import { Button, TextField, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import baseURL from "../../assets/config/baseUrl";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formContainer: {
    fontSize: 25,
    width: "100vw",
    height: "100vh",
  },
  formRight: {
    backgroundColor: theme.palette.secondary.main,
  },
  formText: {
    padding: theme.spacing(2),
    fontFamily: "Lexend",
    fontSize: 50,
    width: "100%",
    color: theme.palette.primary,
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  logoText: {
    fontFamily: "Lexend",
    fontSize: 200,
    color: theme.palette.primary.contrastText,
  },

  formControl: {
    margin: theme.spacing(1),
    width: 500,
    color: theme.palette.primary.main,
  },
  formItem: {
    margin: 10,
    color: theme.palette.primary.main,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    color: "white",
  },
  paper: {
    textAlign: "left",
    padding: theme.spacing(5),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[10],
    backgroundColor: theme.palette.background.paper,
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(7.5),
    margin: theme.spacing(10),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[10],
  },
  footer: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    backgroundColor: theme.palette.primary.light,
    left: 0,
    textAlign: "right",
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  background: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
    position: "absolute",
    backgroundColor: "#050a12",
  },
  backgroundImage: {
    backgroundImage: `url(https://images.unsplash.com/photo-1638184984605-af1f05249a56?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3132&q=80)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
    opacity: 0.2,
  },
}));

function Login({
  setAuthenticated,
  setAccessToken,
  setIdToken,
  setRefreshToken,
  setAdmin,
  setProfile,
}) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [requireNewPassword, setRequireNewPassword] = useState(false);
  const [requireCode, setRequireCode] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    handleLogin();
  }
  async function resetPassword() {
    let data = { username: email, password: password, code: code };
    let url = `${baseURL}authentication/ResetPassword`;
    await axios
      .post(url, data)
      .then((res) => {
        handleLogin();
      })
      .catch(({ request }) => {
        switch (request.response) {
          case "CodeMismatchException":
            setError("Incorrect Code. Check your email carefully.");
            break;
          case "LimitExceededException":
            setError(
              "Attempts limit exceeded. Please wait and try again later."
            );
            break;
          case "NotAuthorizedException":
            setError("Incorrect Credentials");
            break;
          case "InvalidPasswordException":
            setError("Password Too Weak");
            break;
          default:
            setError("Unknown Error. Contact system administrator.");
            break;
        }
      });
  }
  //Login
  async function handleLogin() {
    let data = { username: email, password: password };
    let url = `${baseURL}authentication/Login`;
    if (requireNewPassword) {
      data["newpassword"] = newPassword;
      url = `${baseURL}authentication/ChangePassword`;
    }

    await axios
      .post(url, data)
      .then((res) => {
        let accessToken = res.data.data.accessToken.jwtToken;
        let idToken = res.data.data.idToken.jwtToken;
        let refreshToken = res.data.data.refreshToken.token;
        if (res.data.data.idToken.payload["custom:Admin"] == "True") {
          setAdmin(true);
        }
        setProfile(parseInt(res.data.data.idToken.payload["profile"]));
        setAccessToken(accessToken);
        setIdToken(idToken);
        setRefreshToken(refreshToken);
        setAuthenticated(true);
      })
      .catch(({ request }) => {
        switch (request.response) {
          case "NewPasswordRequired":
            setRequireNewPassword(true);
            break;
          case "PasswordResetRequiredException":
            setRequireCode(true);
            break;
          case "NotAuthorizedException":
            setError("Incorrect Credentials");
            break;
          case "InvalidPasswordException":
            setError("Password Too Weak");
            break;
          default:
            console.log(request.response);
            break;
        }
      });
  }

  return (
    <div className={classes.background}>
      <div className={classes.backgroundImage} />

      <Grid
        container
        component="main"
        className={classes.formContainer}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <Grid item xs={false} sm={4} md={7}>
          <Typography className={classes.logoText} style={{ zIndex: 10 }}>
            STRUT
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={5} elevation={6} square>
          <Paper className={classes.colouredPaper} style={{ zIndex: 200 }}>
            <Typography className={classes.formText}>Sign in</Typography>
            <Paper className={classes.paper}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                variant="outlined"
                autoFocus
                value={email}
                onInput={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={requireCode ? "New Password" : "Password"}
                type="password"
                id="password"
                variant="outlined"
                autoComplete="current-password"
                value={password}
                onInput={(e) => setPassword(e.target.value)}
              />
              {requireNewPassword && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="new password"
                  label="NewPassword"
                  type="password"
                  variant="outlined"
                  id="new-password"
                  value={newPassword}
                  onInput={(e) => setNewPassword(e.target.value)}
                />
              )}
              {requireCode && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="Code"
                  label="Code"
                  variant="outlined"
                  id="Code"
                  value={code}
                  onInput={(e) => setCode(e.target.value)}
                />
              )}
              <Typography>{error}</Typography>
              {requireCode ? (
                <div style={{ marginTop: 40 }}>
                  <Button
                    onClick={(e) => {
                      resetPassword();
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {"Reset Password"}
                  </Button>
                </div>
              ) : (
                <div style={{ marginTop: 40 }}>
                  <Button
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {"Sign In"}
                  </Button>
                  <Button
                    onClick={(e) => {
                      setRequireCode(true);
                    }}
                    fullWidth
                    variant="contained"
                    color="secondary"
                  >
                    {"Reset Password"}
                  </Button>
                </div>
              )}
            </Paper>
          </Paper>
        </Grid>
      </Grid>
      <div className={classes.footer}>
        <a href={"https://kelp-tech.com"}>
          <Typography style={{ fontSize: 12, color: "white" }}>
            Kelp Technologies | https://kelp-tech.com
          </Typography>
        </a>
      </div>
    </div>
  );
}
export default Login;
