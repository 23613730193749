import React from "react";
import { Grid } from "@material-ui/core";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ItemCard from "./ItemCard";

function SavedItemContainer({ savedItems, brands, types, decades, seasons }) {
  //List Items
  return (
    <Grid
      container
      spacing={2}
      scrollable="true"
      style={{
        overflowY: "auto",
        height: 500,
        width: "100%",
        listStyle: "none",
        "&::WebkitScrollbar": {
          width: "0.4em",
        },
        "&::WebkitScrollbarTrack": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::WebkitScrollbarThumb": {
          backgroundColor: "rgba(0,0,0,.1)",
          outline: "1px solid slategrey",
        },
      }}
    >
      {savedItems.map((item) => {
        return (
          <ItemCard
            item={item}
            brands={brands}
            types={types}
            decades={decades}
            seasons={seasons}
          />
        );
      })}
    </Grid>
  );
}

export default SavedItemContainer;
