import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Paper, Button, TextField } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import axios from "axios";
import baseURL from "../../assets/config/baseUrl";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formContainer: {
    textAlign: "left",
  },
  buttonContainer: {
    width: "70%",
    height: 100,
  },
  select: {
    height: 50,
    width: "90%",
  },
  formItem: {
    fontFamily: "Lexend",
    fontSize: 15,
    color: "1F1f1f",
    textAlign: "center",
  },
  number: {
    fontFamily: "Lexend",
    fontSize: 50,
    color: "1F1f1f",
    textAlign: "center",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    color: "white",
  },
  text: {
    margin: 15,
    fontFamily: "Lexend",
    fontSize: 20,
    color: "#f4f5f5",
  },
  paper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[10],
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[10],
  },
  formControl: {},

  success: {
    borderRadius: 10,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.background.default,
    },
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderRadius: 10,
    borderWidth: 2,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.background.default,
      borderColor: theme.palette.error.main,
    },
  },
  buttonGroup: {
    border: `3px solid ${theme.palette.stroke.main}`,
    borderRadius: 100,
    overflow: "hidden",
    marginTop: theme.spacing(4),
  },
}));

export default function CreateUser({ accessToken, admin }) {
  const classes = useStyles();
  let navigate = useNavigate();
  useEffect(() => {
    if (!admin) {
      navigate("/");
    }
  }, [admin]);
  const [profilePicture, setProfilePicture] = useState();
  const [username, setUsername] = useState();
  const [canUpload, setCanUpload] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [successfulUpload, setSuccessfulUpload] = useState(false);
  const handleFile = (event) => {
    setProfilePicture(event.target.files[0]);
  };

  const handleUsername = (event) => {
    setUsername(event.target.value);
  };

  async function Upload() {
    setCanUpload(false);

    const config = {
      headers: { accessToken: accessToken },
    };

    const data = new FormData();
    data.append("username", username);
    data.append("image", profilePicture);

    await axios
      .post(`${baseURL}uploaddata/createdummyuser`, data, config)
      .then((res) => {
        setProfilePicture();
        setUsername("");
        setSuccessfulUpload(true);
        setShowAlert(true);
      })
      .catch((err) => {
        setSuccessfulUpload(false);
        setShowAlert(true);
      });
  }
  useEffect(() => {
    if (profilePicture && username) {
      setCanUpload(true);
    } else {
      setCanUpload(false);
    }
  }, [setCanUpload, username, profilePicture]);

  return (
    <>
      {showAlert &&
        (successfulUpload ? (
          <Alert
            severity="success"
            onClose={() => {
              setShowAlert(false);
            }}
          >
            <Typography>User Created Successfully</Typography>
          </Alert>
        ) : (
          <Alert
            severity="error"
            onClose={() => {
              setShowAlert(false);
            }}
          >
            <Typography>User Creation Failed</Typography>
          </Alert>
        ))}

      <Grid container spacing={2} style={{ padding: 15 }}>
        <Grid item xs={2}>
          <Paper className={classes.colouredPaper}>
            <Typography>Profile Picture:</Typography>
          </Paper>
          {!profilePicture ? (
            <Button
              variant="contained"
              component="label"
              className={classes.success}
            >
              Upload File
              <input type="file" name="file" hidden onChange={handleFile} />
            </Button>
          ) : (
            <Paper className={classes.paper}>
              <img
                alt="Spotlight Cover"
                src={URL.createObjectURL(profilePicture)}
                style={{ width: "100%", borderRadius: 300 }}
              />
            </Paper>
          )}
        </Grid>
        <Grid item xs={10}>
          <Paper className={classes.colouredPaper}>
            <Typography>User Details</Typography>
          </Paper>
          <Paper
            elevation={3}
            className={classes.paper}
            style={{
              textAlign: "center",
            }}
          >
            <div>
              <TextField
                id="outlined-multiline-flexible"
                label="Username"
                multiline
                variant="outlined"
                maxRows={10}
                value={username}
                onChange={handleUsername}
                style={{ width: "100%" }}
              />
            </div>
          </Paper>
        </Grid>
        <Grid xs={12}>
          <Paper
            className={classes.colouredPaper}
            style={{ textAlign: "right" }}
          >
            <Button
              variant="contained"
              component="label"
              disabled={!canUpload}
              className={classes.success}
              onClick={() => Upload()}
            >
              UPLOAD
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
