import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Button, TextField } from "@material-ui/core";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[2],
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[2],
    height: "100%",
  },
  success: {
    borderRadius: 10,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.background.default,
    },
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderRadius: 10,
    borderWidth: 2,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.background.default,
      borderColor: theme.palette.error.main,
    },
  },
}));
export default function InstructionCard({
  identifyInstructions,
  setIdentifyInstructions,
  index,
}) {
  const [selectedFile, setSelectedFile] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const classes = useStyles();

  const handleFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    setIdentifyInstructions([
      ...identifyInstructions.slice(0, index),
      { title: title, description: description, file: selectedFile },
      ...identifyInstructions.slice(index + 1),
    ]);
  }, [title, description, selectedFile]);

  function deleteInstruction() {
    setIdentifyInstructions([
      ...identifyInstructions.slice(0, index),
      ...identifyInstructions.slice(index + 1),
    ]);
  }
  return (
    <Grid item xs={3}>
      <Paper className={classes.colouredPaper}>
        <div>
          <Button
            variant="outlined"
            component="label"
            className={classes.error}
            onClick={() => {
              deleteInstruction();
            }}
          >
            DELETE
          </Button>
        </div>
        <div>
          {!selectedFile ? (
            <Button
              variant="contained"
              component="label"
              className={classes.success}
            >
              Upload File
              <input type="file" name="file" hidden onChange={handleFile} />
            </Button>
          ) : (
            <img
              src={URL.createObjectURL(selectedFile)}
              style={{ width: "100%" }}
            />
          )}
        </div>
        <div style={{ margin: 10 }}>
          <TextField
            id="outlined-multiline-flexible"
            label="Name"
            multiline
            variant="outlined"
            maxRows={10}
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            style={{ width: "100%", marginTop: 10 }}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Description"
            multiline
            maxRows={10}
            variant="outlined"
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            style={{ width: "100%", marginTop: 10 }}
          />
        </div>
      </Paper>
    </Grid>
  );
}
