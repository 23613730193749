import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Paper } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { YoutubeSearchedForOutlined } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  paper: {
    textAlign: "left",
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[10],
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[10],
    height: "100%",
  },
  savedPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[10],
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },

  success: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  buttonGroup: {
    border: `3px solid ${theme.palette.stroke.main}`,
    borderRadius: 100,
    overflow: "hidden",
    marginTop: theme.spacing(4),
  },
  gridItem: {
    textAlign: "center",
    padding: 10,
  },
}));

function ItemCard({ item, brands, types, decades, seasons }) {
  const classes = useStyles();
  const [type, setType] = useState();
  const [brand, setBrand] = useState();
  const [decade, setDecade] = useState();
  const [season, setSeason] = useState();
  const [year, setYear] = useState(-1);

  // Functions as Callbacks
  const setItemProperties = useCallback(() => {
    if (!item.id) {
      setYear(item.year);
      setType(
        types.filter((x) => {
          return x.id === item.type;
        })[0]
      );
      setBrand(
        brands.filter((x) => {
          return x.id === item.brand;
        })[0]
      );
      setDecade(
        decades.filter((x) => {
          return x.id === item.decade;
        })[0]
      );
      setSeason(
        seasons.filter((x) => {
          return x.id === item.season;
        })[0]
      );
    }
  }, [brands, decades, seasons, types, item]);

  // useEffect Hooks
  useEffect(() => {
    setItemProperties();
  }, [setItemProperties]);

  return (
    <Grid item xs={1}>
      {item.id && (
        <div
          style={{
            position: "absolute",
            maxWidth: "100%",
            textAlign: "center",
            opacity: 0.8,
            zIndex: 10,
          }}
        >
          <VisibilityIcon sx={{ fontSize: 50 }} />
        </div>
      )}
      <Paper className={classes.paper} style={{ position: "relative" }}>
        {item.id ? (
          <>
            <div style={{ width: "100%" }}>
              <img
                src={URL.createObjectURL(item.files[0])}
                style={{ width: "100%" }}
                alt="garment"
              />
            </div>
          </>
        ) : (
          <>
            <div style={{ width: "100%" }}>
              <img
                src={URL.createObjectURL(item.files[0])}
                style={{ width: "100%" }}
                alt="garment"
              />
            </div>
            <div style={{ padding: 10 }}>
              <Typography>{item.name ? item.name : "Not Entered"}</Typography>
              <Typography>{brand ? brand.name : "Not Entered"}</Typography>
              <Typography>{type ? type.name : "Not Entered"}</Typography>
              <Typography>{decade ? decade.name : "Not Entered"}</Typography>
              <Typography>{year ? year : "Not Entered"}</Typography>
              <Typography>{season ? season.name : "Not Entered"}</Typography>
            </div>
          </>
        )}
      </Paper>
    </Grid>
  );
}

export default ItemCard;
