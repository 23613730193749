import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  Avatar,
  Divider,
} from "@material-ui/core";
import CustomAutocomplete from "../../Components/CustomAutocomplete";

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "left",
    margin: 20,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[10],
    alignItems: "flex-end",
    flex: 1,
    alignItems: "center",
    alignContent: "center",
    textAlign: "flex-end",
    maxWidth: 300,
  },
  savedPaper: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
    textAlign: "left",
    margin: 20,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[10],
    alignItems: "flex-end",
    flex: 1,
    alignItems: "center",
    alignContent: "center",
    textAlign: "flex-end",
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  buttonGroup: {
    border: `3px solid ${theme.palette.stroke.main}`,
    borderRadius: 100,
    overflow: "hidden",
    marginTop: theme.spacing(4),
  },
  gridItem: {
    textAlign: "center",
    padding: 10,
    paddingRight: 0,
  },
  formControl: {
    width: 200,
    marginTop: theme.spacing(2),
  },
}));

function RowItem({ item, brands, types, seasons, decades, addToSave }) {
  const value = item[1];
  const classes = useStyles();
  const [brand, setBrand] = useState();
  const [collab, setCollab] = useState();
  const [type, setType] = useState();
  const [decade, setDecade] = useState();
  const [year, setYear] = useState(-1);

  const [season, setSeason] = useState();
  const [id, setId] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [name, setName] = useState("");

  const handleBrandSelect = (value) => {
    setBrand(value);
  };
  const handleCollabSelect = (value) => {
    setCollab(value);
  };
  const handleTypeSelect = (value) => {
    setType(value);
  };
  const handleSeasonSelect = (value) => {
    setSeason(value);
  };
  const handleDecadeSelect = (value) => {
    setDecade(value);
  };
  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleYear = (event) => {
    console.log(event.target.value == "", event.target.value);
    if (event.target.value == "") {
      setYear(-1);
    } else if (event.target.value >= -1 && event.target.value <= 9) {
      setYear(event.target.value);
    }
    console.log(year);
  };
  function saveItem() {
    let data = {
      files: value,
      brand: brand,
      type: type,
      decade: decade,
      season: season,
      id: id,
      collab: collab,
      name: name,
      year: year,
    };
    setDisabled(true);
    addToSave(data);
  }
  useEffect(() => {
    console.log("Brand", brand);
    console.log("type", type);
    console.log("decade", decade);
    console.log("season", season);
  }, [brand, type, decade, season, name]);
  return (
    <Paper className={disabled ? classes.savedPaper : classes.paper}>
      <img
        style={{ height: 100 }}
        src={URL.createObjectURL(value[0])}
        alt="garment"
      />

      <TextField
        id="outlined-multiline-flexible"
        label="Name"
        variant="outlined"
        value={name}
        onChange={handleName}
      />
      <FormControl className={classes.formControl}>
        <CustomAutocomplete
          options={brands}
          label={"brand"}
          onChange={(value) => {
            handleBrandSelect(value);
          }}
          reset={true}
          optionValue={"name"}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <CustomAutocomplete
          options={brands}
          label={"collab"}
          onChange={(value) => {
            handleCollabSelect(value);
          }}
          reset={true}
          optionValue={"name"}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <CustomAutocomplete
          options={types}
          label={"type"}
          onChange={(value) => {
            handleTypeSelect(value);
          }}
          reset={true}
          optionValue={"name"}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <CustomAutocomplete
          options={decades}
          label={"decade"}
          onChange={(value) => {
            handleDecadeSelect(value);
          }}
          reset={true}
          optionValue={"name"}
        />
      </FormControl>
      <TextField
        id="outlined-multiline-flexible"
        label="year"
        variant="outlined"
        value={year}
        onChange={handleYear}
        className={classes.formControl}
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControl className={classes.formControl}>
        <CustomAutocomplete
          options={seasons}
          label={"season"}
          onChange={(value) => {
            handleSeasonSelect(value);
          }}
          reset={true}
          optionValue={"name"}
        />
      </FormControl>
      <Divider style={{ margin: 20 }} />
      <div style={{ width: "100%", display: "flex", marginTop: 10 }}>
        <div style={{ flex: 1 }}>
          <InputLabel
            id="demo-simple-select-label"
            className={classes.formItem}
          >
            ID
          </InputLabel>
          <Checkbox
            checked={id}
            disabled={disabled}
            onChange={(e) => {
              setId(!id);
            }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Button
            variant="contained"
            component="label"
            className={classes.success}
            disabled={
              disabled ||
              (!id && (!brand || !type || !decade || !season || !name))
            }
            onClick={() => {
              saveItem();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Paper>
  );
}

export default RowItem;
