import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Paper,
  Button,
  FormControl,
} from "@material-ui/core";

import axios from "axios";
import baseURL from "../../assets/config/baseUrl";
import ItemContainer from "./ItemContainer";
import SavedItemContainer from "./SavedItemContainer";
import Popup from "../../Components/Popup";
import CustomAutocomplete from "../../Components/CustomAutocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formContainer: {
    textAlign: "left",
  },
  buttonContainer: {
    width: "70%",
    height: 100,
  },
  select: {
    height: 50,
    width: "90%",
  },
  formItem: {
    fontFamily: "Lexend",
    fontSize: 15,
    color: "1F1f1f",
    textAlign: "center",
  },
  number: {
    fontFamily: "Lexend",
    fontSize: 50,
    color: "1F1f1f",
    textAlign: "center",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    color: "white",
  },
  text: {
    margin: 15,
    fontFamily: "Lexend",
    fontSize: 20,
    color: "#f4f5f5",
  },
  paper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[10],
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[10],
  },
  formControl: {},

  success: {
    borderRadius: 10,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.background.default,
    },
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderRadius: 10,
    borderWidth: 2,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.background.default,
      borderColor: theme.palette.error.main,
    },
  },
  buttonGroup: {
    border: `3px solid ${theme.palette.stroke.main}`,
    borderRadius: 100,
    overflow: "hidden",
    marginTop: theme.spacing(4),
  },
}));

export default function BulkUpload({ accessToken, admin, profile }) {
  const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [itemCount, setItemCount] = useState(0);
  const [imageCount, setImageCount] = useState(0);
  const [savedItems, setSavedItems] = useState([]);
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [decades, setDecades] = useState([]);
  const [seasons, setSeasons] = useState([]);
  //Screen config
  const [modalOpen, setModalOpen] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [submissionMade, setSubmissionMade] = useState(false);
  const [successfulUploads, setSuccessfulUploads] = useState(0);
  const [unsuccessfulUploads, setUnsuccessfulUploads] = useState(0);
  const [resetAC, setResetAC] = useState(false);
  // Functions as Constants
  const handleFiles = (event) => {
    let files = {};
    for (var i = 0; i < event.target.files.length; i++) {
      let filename = event.target.files[i].name.split(".")[0];
      let splitname = filename.split("-");
      let prefix = splitname[0];
      try {
        files[prefix].push(event.target.files[i]);
      } catch {
        files[prefix] = [event.target.files[i]];
      }
    }
    setItemCount(Object.entries(files).length);
    setSelectedFiles(files);
    setImageCount(event.target.files.length);
  };

  // Functions as Callbacks
  const getTypes = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}general/getbytype/Type`, config)
      .then((res) => {
        setTypes(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);
  const getBrands = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}general/getbytype/Brand`, config)
      .then((res) => {
        setBrands(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);
  const getSeasons = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}general/getbytype/Season`, config)
      .then((res) => {
        setSeasons(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);
  const getDecades = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}general/getbytype/Decade`, config)
      .then((res) => {
        setDecades(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);
  const getUsers = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}general/getbytype/User`, config)
      .then((res) => {
        if (!admin) {
          console.log(profile);
          setSelectedUser(profile);
        }
        setUsers(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);

  // Functions
  async function postData() {
    setSubmissionMade(true);
    setButtonsDisabled(true);
    await Promise.all(
      savedItems.map(async (item) => {
        let success = await uploadGarment(item);
        return success;
      })
    ).then((data) => {
      let total = data.length;
      let successful = data.filter((d) => d === true).length;
      let unsuccessful = total - successful;
      setUnsuccessfulUploads(unsuccessful);
      setSuccessfulUploads(successful);
      setButtonsDisabled(false);
    });
  }

  async function uploadGarment(item) {
    const config = {
      headers: {
        accessToken: accessToken,
      },
    };
    const data = new FormData();
    if (item.name) {
      data.append("name", item.name);
    }
    if (item.brand) {
      data.append("brand", item.brand);
    }
    if (item.collab) {
      data.append("collabBrand", item.collab);
    }
    if (item.type) {
      data.append("type", item.type);
    }
    if (item.season) {
      data.append("season", item.season);
    }
    if (item.decade) {
      data.append("decade", item.decade);
    }
    data.append("year", item.year);
    if (item.id) {
      data.append("uploadType", "IDENTIFY");
    } else {
      data.append("uploadType", "PENDING");
    }
    data.append("creator", selectedUser);
    Object.entries(item.files).map((x) => data.append("images", x[1]));
    const success = await axios
      .post(`${baseURL}garments/creategarment`, data, config)
      .then(() => {
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
    return success;
  }
  function reset() {
    setSelectedFiles([]);
    setSelectedUser();
    setItemCount(0);
    setImageCount(0);
    setSavedItems([]);
    setSubmissionMade(false);
    setResetAC(!resetAC);
  }

  // useEffect Hooks
  useEffect(() => {
    getUsers();
    getTypes();
    getBrands();
    getSeasons();
    getDecades();
    return () => {
      setUsers([]);
      setTypes([]);
      setSelectedFiles([]);
      setSelectedUser();
      setItemCount(0);
      setImageCount(0);
      setSavedItems([]);
      setBrands([]);
      setSeasons([]);
      setDecades([]);
    };
  }, [getDecades, getUsers, getBrands, getSeasons, getTypes]);

  return (
    <>
      <Popup
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        disabled={false}
        title={"Are you sure you want to upload?"}
      >
        <Grid container>
          {submissionMade ? (
            <>
              <Grid item xs={12}>
                <Typography>{`Successful Uploads: ${successfulUploads}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{`Unsuccessful Uploads: ${unsuccessfulUploads}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  className={classes.error}
                  disabled={buttonsDisabled}
                  onClick={(e) => {
                    setModalOpen(false);
                    reset();
                  }}
                >
                  Close
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  className={classes.success}
                  disabled={buttonsDisabled}
                  onClick={(e) => {
                    postData();
                  }}
                >
                  Confirm
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  className={classes.error}
                  disabled={buttonsDisabled}
                  onClick={(e) => {
                    setModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Popup>
      <Grid container spacing={2} style={{ padding: 15 }}>
        <Grid item xs={2}>
          <Paper className={classes.colouredPaper}>
            <Typography>Upload Files</Typography>
          </Paper>
          <Paper
            elevation={3}
            className={classes.paper}
            style={{
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              component="label"
              disabled={!selectedUser}
              className={classes.success}
            >
              Upload
              <input
                type="file"
                multiple
                name="file"
                hidden
                onChange={handleFiles}
              />
            </Button>
            <FormControl fullWidth>
              <CustomAutocomplete
                options={users}
                value={selectedUser}
                label={"select a user"}
                disabled={!admin}
                onChange={(value) => {
                  setSelectedUser(value);
                }}
                reset={resetAC}
                optionValue={"username"}
              />
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={10}>
          <Paper className={classes.colouredPaper}>
            <Grid container>
              <Grid item xs={2}>
                <Typography>Tag Items:</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Item Count: {itemCount}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Image Count: {imageCount}</Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper
            elevation={3}
            className={classes.paper}
            style={{
              textAlign: "center",
            }}
          >
            <ItemContainer
              items={selectedFiles}
              accessToken={accessToken}
              savedItems={savedItems}
              setSavedItems={setSavedItems}
              brands={brands}
              types={types}
              decades={decades}
              seasons={seasons}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.colouredPaper}>
            <Typography>Saved Items</Typography>
          </Paper>
          <Paper
            elevation={3}
            className={classes.paper}
            style={{
              textAlign: "center",
            }}
          >
            <SavedItemContainer
              items={selectedFiles}
              accessToken={accessToken}
              savedItems={savedItems}
              setSavedItems={setSavedItems}
              brands={brands}
              types={types}
              decades={decades}
              seasons={seasons}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            className={classes.colouredPaper}
            style={{ textAlign: "right" }}
          >
            <Button
              variant={"contained"}
              disabled={savedItems.length === 0 || !selectedUser}
              onClick={() => {
                setModalOpen(true);
              }}
              className={classes.success}
            >
              SUBMIT
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
