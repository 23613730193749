import React from "react";
import {
  Typography,
  Paper,
  Button,
  Modal,
  Fade,
  List,
  Backdrop,
  makeStyles,
} from "@material-ui/core";

const createStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 400,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  colouredPaper: {
    width: 400,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: 10,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[2],
  },
}));
function Popup({ disabled, modalOpen, children, setModalOpen, title }) {
  const styles = createStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modal}
      open={modalOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalOpen} style={{ outline: "none" }}>
        <div style={{ display: "inline" }}>
          <Paper className={styles.colouredPaper}>
            <Typography variant="h5">{title}</Typography>
          </Paper>
          <Paper className={styles.paper}>{children}</Paper>
        </div>
      </Fade>
    </Modal>
  );
}

export default Popup;
