import React, { useEffect, useState, useCallback } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

function CustomAutocomplete({
  options,
  label,
  onChange,
  reset,
  optionValue,
  selectedID,
  disabled = false,
}) {
  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();
  const [value, setValue] = useState(optionValue);
  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };
  useEffect(() => {
    setValue(optionValue);
    return () => {
      setValue(optionValue);
    };
  }, [optionValue]);
  return (
    <Autocomplete
      disablePortal
      key={reset}
      filterOptions={filterOptions}
      id="combo-box-demo"
      options={options}
      disabled={disabled}
      renderInput={(params) => {
        return (
          <TextField
            InputProps={{ style: { fontSize: 3 } }}
            {...params}
            label={label}
            variant="outlined"
          />
        );
      }}
      onChange={(event, value) => {
        if (value == null) {
          onChange(null);
        } else {
          onChange(value.id);
        }
      }}
      getOptionLabel={(option) => {
        return option[value].toString();
      }}
      clearOnBlur={true}
      getOptionSelected={(option, value) => {
        return option.id === value.id;
      }}
    />
  );
}

export default CustomAutocomplete;
