import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@material-ui/core";
import Alert from "@mui/material/Alert";
import ProductCard from "../../Components/ProductCard";

import axios from "axios";
import baseURL from "../../assets/config/baseUrl";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Popup from "../../Components/Popup";
import CustomAutocomplete from "../../Components/CustomAutocomplete";
import GarmentPanel from "./GarmentPanel";
import FilterBar from "../../Components/FilterBar";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "pink",
  },
  formContainer: {
    textAlign: "center",
  },
  paper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[10],
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[10],
  },
  formControl: {
    width: "100%",
    marginTop: 10,
  },
  formItem: {
    fontFamily: "Lexend",
    fontSize: 15,
  },
  success: {
    borderRadius: 10,
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.background.default,
    },
  },
  error: {
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,

    borderRadius: 10,
    borderWidth: 2,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
      borderColor: theme.palette.error.main,
    },
  },
}));

export default function EditGarment({ accessToken, admin }) {
  // Only allow Admins
  let navigate = useNavigate();
  useEffect(() => {
    if (!admin) {
      navigate("/");
    }
  }, [admin]);
  //Screen Config
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successfulUpload, setSuccessfulUpload] = useState(false);
  const [filters, setFilters] = useState([]);

  //General
  const [garments, setGarments] = useState([]);
  const [garmentData, setGarmentData] = useState();
  const [searchterm, setSearchterm] = useState("");
  const [selectedGarment, setSelectedGarment] = useState();

  //dropdown values
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [decades, setDecades] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  //new values
  const [brand, setBrand] = useState();
  const [collabBrand, setCollabBrand] = useState();
  const [type, setType] = useState();
  const [decade, setDecade] = useState();
  const [year, setYear] = useState();
  const [season, setSeason] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();

  // Functions as constants

  // Functions as callbacks

  const getData = useCallback(async () => {
    const config = {
      headers: { accessToken: `${accessToken}` },
    };
    await axios
      .get(`${baseURL}garments/${selectedGarment}/1587`, config)
      .then((res) => {
        setButtonDisabled(false);

        setGarmentData(res.data.data);
        setName(res.data.data.name);
        setType(res.data.data.Type.id);
        setBrand(res.data.data.Brand.id);
        setDecade(res.data.data.Decade.id);
        setSeason(res.data.data.Season.id);
        setYear(res.data.data.Year);
        setCollabBrand(res.data.data.collabBrand.id);
        setDescription(res.data.data.description);
      })
      .catch((err) => console.log(err));
  }, [accessToken, selectedGarment]);

  const getFilters = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };

    await axios
      .get(`${baseURL}general/getbytype/Brand`, config)
      .then((res) => {
        setBrands(res.data.data);
      })
      .catch((err) => console.log(err));

    await axios
      .get(`${baseURL}general/getbytype/Type`, config)
      .then((res) => {
        setTypes(res.data.data);
      })
      .catch((err) => console.log(err));
    await axios
      .get(`${baseURL}general/getbytype/Season`, config)
      .then((res) => {
        setSeasons(res.data.data);
      })
      .catch((err) => console.log(err));
    await axios
      .get(`${baseURL}general/getbytype/Decade`, config)
      .then((res) => {
        setDecades(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);

  const getGarments = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .post(`${baseURL}garments/filter`, filters, config)
      .then((res) => {
        setGarments(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [accessToken, filters]);

  // Functions

  async function Upload() {
    setButtonDisabled(true);
    const config = {
      headers: { accessToken: accessToken },
    };
    let data = {
      brand: brand,
      type: type,
      decade: decade,
      season: season,
      garmentID: selectedGarment,
      name: name,
      year: year,
      description: description,
    };
    if (collabBrand != -1) {
      data["collabBrand"] = collabBrand;
    }
    await axios
      .post(`${baseURL}garments/update`, data, config)
      .then((res) => {
        setGarmentData();
        setSuccessfulUpload(true);
        setShowAlert(true);
        setSelectedGarment();
      })
      .catch((err) => {
        setSuccessfulUpload(false);
        setShowAlert(true);
      });
  }

  async function Delete() {
    setButtonDisabled(true);
    const config = {
      headers: { accessToken: accessToken },
    };
    let d = {
      id: selectedGarment,
    };
    console.log(d);
    await axios
      .post(`${baseURL}garments/deletegarment`, { id: selectedGarment }, config)
      .then((res) => {
        setGarmentData();
        setSuccessfulUpload(true);
        setShowAlert(true);
        setSelectedGarment();
      })
      .catch((err) => {
        setSuccessfulUpload(false);
        setShowAlert(true);
      });
  }
  const handleYear = (event) => {
    if (event.target.value == "") {
      setYear(-1);
    } else if (event.target.value >= -1 && event.target.value <= 9) {
      setYear(event.target.value);
    }
  };

  // UseEffects
  useEffect(() => {
    if (selectedGarment) {
      getData();
    }
  }, [selectedGarment, getData]);

  useEffect(() => {
    getGarments();
    return () => {
      setGarmentData();
      setGarments([]);
    };
  }, [getGarments]);

  useEffect(() => {
    getFilters();
    return () => {
      setGarmentData();
      setGarments([]);
    };
  }, [getFilters]);
  return (
    <>
      {showAlert &&
        (successfulUpload ? (
          <Alert
            severity="success"
            onClose={() => {
              setShowAlert(false);
            }}
          >
            <Typography>Action Successful</Typography>
          </Alert>
        ) : (
          <Alert
            severity="error"
            onClose={() => {
              setShowAlert(false);
            }}
          >
            <Typography>Action Failed</Typography>
          </Alert>
        ))}
      <div style={{ margin: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={8} style={{ textAlign: "center" }}>
            <Paper className={classes.colouredPaper}>
              <Typography>Garment Selection and Information</Typography>
            </Paper>

            <FilterBar
              accessToken={accessToken}
              filters={filters}
              setFilters={setFilters}
            />
            <Paper className={classes.paper}>
              <Grid
                container
                spacing={2}
                scrollable={"true"}
                style={{
                  overflowY: "auto",
                  height: 530,
                  listStyle: "none",
                  "&::WebkitScrollbar": {
                    width: "0.4em",
                  },
                  "&::WebkitScrollbarTrack": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::WebkitScrollbarThumb": {
                    backgroundColor: "rgba(0,0,0,.1)",
                    outline: "1px solid slategrey",
                  },
                }}
              >
                {garments &&
                  garments.map((garment) => {
                    return (
                      <GarmentPanel
                        garment={garment}
                        selectedGarment={selectedGarment}
                        setSelectedGarment={setSelectedGarment}
                      />
                    );
                  })}
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={4}>
            <Paper className={classes.colouredPaper}>
              <Typography>Garment Data</Typography>
            </Paper>

            <Paper
              sx={{
                minHeight: 400,
              }}
              elevation={3}
              rounded={"true"}
              className={classes.paper}
            >
              {garmentData && (
                <>
                  <Typography>Name: {garmentData.name}</Typography>
                  <Typography>Brand: {garmentData.Brand.name}</Typography>
                  {garmentData.collabBrand && (
                    <Typography>
                      Collab: {garmentData.collabBrand.name}
                    </Typography>
                  )}
                  <Typography>Type: {garmentData.Type.name}</Typography>
                  <Typography>Decade: {garmentData.Decade.name}</Typography>
                  <Typography>Season: {garmentData.Season.name}</Typography>
                  <Typography>Year: {garmentData.Year}</Typography>
                </>
              )}
            </Paper>
            <Paper
              sx={{
                minHeight: 400,
              }}
              elevation={3}
              rounded={"true"}
              className={classes.paper}
            >
              {garmentData && (
                <TextField
                  id="outlined-flexible"
                  label="Description"
                  maxRows={10}
                  value={description ? description : ""}
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                  style={{ width: "95%" }}
                />
              )}
            </Paper>
            <Paper className={classes.colouredPaper}>
              <Typography>Reassign Values</Typography>
            </Paper>
            <Paper
              sx={{
                minHeight: 400,
              }}
              elevation={3}
              rounded={"true"}
              className={classes.paper}
            >
              {garmentData && (
                <>
                  <TextField
                    id="outlined-flexible"
                    label="Name"
                    maxRows={10}
                    value={name ? name : ""}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    style={{ width: "95%" }}
                  />

                  <FormControl className={classes.formControl}>
                    <CustomAutocomplete
                      options={brands}
                      label={"select a brand"}
                      onChange={(value) => {
                        setBrand(value);
                      }}
                      reset={reset}
                      optionValue={"name"}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <CustomAutocomplete
                      options={[{ name: "No Collab", id: -1 }, ...brands]}
                      label={"select a collab"}
                      onChange={(value) => {
                        setCollabBrand(value);
                      }}
                      reset={reset}
                      optionValue={"name"}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <CustomAutocomplete
                      options={types}
                      label={"select a type"}
                      onChange={(value) => {
                        setType(value);
                      }}
                      reset={reset}
                      optionValue={"name"}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <CustomAutocomplete
                      options={decades}
                      label={"select a decade"}
                      onChange={(value) => {
                        setDecade(value);
                      }}
                      reset={reset}
                      optionValue={"name"}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <CustomAutocomplete
                      options={seasons}
                      label={"select a season"}
                      onChange={(value) => {
                        setSeason(value);
                      }}
                      reset={reset}
                      optionValue={"name"}
                    />
                  </FormControl>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="year"
                    variant="outlined"
                    value={year}
                    onChange={handleYear}
                    className={classes.formControl}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </>
              )}
            </Paper>
            <Button
              variant="outlined"
              className={classes.success}
              disabled={buttonDisabled}
              onClick={(e) => {
                Upload();
              }}
            >
              Upload
            </Button>
            <Button
              variant="contained"
              component="label"
              className={classes.error}
              onClick={() => Delete()}
            >
              DELETE
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
