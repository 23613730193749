import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography } from "@material-ui/core";

import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import BarChart from "./Charts/BarChart";
import PieChart from "./Charts/PieChart";
import baseURL from "../../assets/config/baseUrl";
import { Alert } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  formItem: {
    fontFamily: "Lexend",
    fontSize: 20,
    color: "1F1f1f",
    textAlign: "center",
  },
  number: {
    fontFamily: "Lexend",
    fontSize: 50,
    color: "1F1f1f",
    textAlign: "center",
  },

  paper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[10],
    height: "100%",
  },
  colouredPaper: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[10],
    height: "100%",
  },
}));

export default function Insights({ accessToken }) {
  const classes = useStyles();
  const [userCount, setUserCount] = useState(0);
  const [garmentCount, setGarmentCount] = useState(0);
  const [likesCount, setLikesCount] = useState(0);
  const [spotlightCount, setSpotlight] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [showAlert, setShowAlert] = useState(true);

  const getCounts = useCallback(async () => {
    const config = {
      headers: { accessToken: accessToken },
    };
    await axios
      .get(`${baseURL}insights/counts`, config)
      .then((res) => {
        setUserCount(res.data.data.userCount);
        setLikesCount(res.data.data.likeCount);
        setGarmentCount(res.data.data.garmentCount);
        setSpotlight(res.data.data.spotlightCount);
        setPendingCount(res.data.data.pendingCount);
      })
      .catch((err) => console.log(err));
  }, [accessToken]);

  useEffect(() => {
    getCounts();
    return () => {
      setUserCount(0);
      setGarmentCount(0);
      setLikesCount(0);
      setSpotlight(0);
    };
  }, [getCounts]);

  return (
    <>
      {showAlert && (
        <Alert
          severity={pendingCount == 0 ? "success" : "error"}
          onClose={() => {
            setShowAlert(false);
          }}
        >
          <Typography>
            There are {pendingCount} garments awaiting approval
          </Typography>
        </Alert>
      )}
      <div style={{ overflowX: "hidden" }}>
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={6} md={4} style={{ padding: 20 }}>
            <Paper className={classes.paper} elevation={3}>
              <PieChart accessToken={accessToken} />
            </Paper>
          </Grid>
          <Grid item xs={6} md={8} style={{ padding: 20, paddingLeft: 0 }}>
            <Paper className={classes.paper} elevation={3}>
              <BarChart accessToken={accessToken} />
            </Paper>
          </Grid>
          <Grid item xs={3} md={3} style={{ padding: 20, paddingTop: 0 }}>
            <Paper elevation={3} className={classes.colouredPaper}>
              <div className={classes.number}>{garmentCount}</div>
              <div className={classes.formItem}>Garments</div>
            </Paper>
          </Grid>
          <Grid
            item
            xs={3}
            md={3}
            style={{ padding: 20, paddingTop: 0, paddingLeft: 0 }}
          >
            <Paper elevation={3} className={classes.colouredPaper}>
              <div className={classes.number}>{userCount}</div>
              <div className={classes.formItem}>Users</div>
            </Paper>
          </Grid>
          <Grid
            item
            xs={3}
            md={3}
            style={{ padding: 20, paddingTop: 0, paddingLeft: 0 }}
          >
            <Paper className={classes.colouredPaper} elevation={3}>
              <div className={classes.number}>{likesCount}</div>
              <div className={classes.formItem}>Likes</div>
            </Paper>
          </Grid>
          <Grid
            item
            xs={3}
            md={3}
            style={{ padding: 20, paddingTop: 0, paddingLeft: 0 }}
          >
            <Paper className={classes.colouredPaper} elevation={3}>
              <div className={classes.number}>{spotlightCount}</div>
              <div className={classes.formItem}>Spotlights</div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
